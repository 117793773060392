import React from 'react';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import { StaticImage } from 'gatsby-plugin-image';

function Card({title, description, link}){
  const {t} = useTranslation("grant");
  return <div className="bg-slate-100 md:aspect-square rounded-md overflow-hidden">
          <div className=" p-3 lg:p-6 flex flex-col h-full justify-end">
            <h2 className="font-medium text-xl">{title}</h2>
            <p className="text-slate-500 mt-1">{description}</p>
            <a href={link} target={"_blank"} rel="noreferrer" className="text-[#566BC4] underline hover:opacity-80">{t("info")}</a>
          </div>
        </div>
}

export function GrantList(){
  const {t} = useTranslation("grant");
  return <div className="py-12">
          <div className="w-full lg:max-w-3xl mx-auto text-center pb-3 md:pb-8">
            <h3 className="font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("grants-list.title")}</h3>
            <p className="px-6 text-slate-600 my-6 leading-[1.4] tracking-wide text-lg">{t("grants-list.description")}</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mx-auto max-w-7xl px-6 lg:px-3 gap-6 my-6">
            <Card title={t("scale-ai.title")} description={t("scale-ai.description")} link={"https://www.scaleai.ca"}/>
            <Card title={t("mei.title")} description={t("mei.description")} link={"https://www.economie.gouv.qc.ca/bibliotheque/programmes"}/>
            <Card title={t("pari.title")} description={t("pari.description")} link={"https://nrc.canada.ca/fr/soutien-linnovation-technologique"}/>
            <Card title={t("invest-ai.title")} description={t("invest-ai.description")} link={"https://ivadolabs.com/fr/invest-ia/"}/>
          </div>
          <p className="text-center mx-auto max-w-4xl px-6 py-6 text-xl text-[#566BC4]">{t("other-funds")}</p>
        </div>
}


export function HectiqCanHelp(){
  const {t} = useTranslation("grant");
  return <div className="py-12 md:py-24 bg-slate-50">
          <div className="md:flex items-center w-full lg:max-w-6xl mx-auto px-6 md:space-x-12 space-y-6 md:space-y-0">
            <div className="flex-1 max-w-sm mx-auto">
                <StaticImage src={"../../images/office/pencil.jpg"} imgClassName="rounded-full"/>
            </div>
            <div className="max-w-xl xl:max-w-3xl">
              <h3 className="font-bold text-gray-800 font-serif leading-[1.2] tracking-wide text-4xl">{t("hectiq-can-help.title")}</h3>
              <p className="text-slate-600 my-6 leading-[1.4] tracking-wide text-lg">{t("hectiq-can-help.description")}</p>
              <ul className="block inlin">
                <li className="inline-flex xl:block my-2 ml-2 text-lg text-gray-600 leading-[1.6]">
                  <span><p className="bg-[#3B487F] h-2 w-2 rounded-full mt-2 mr-5 inline-flex "/></span>
                  <span className="inline-flex">{t("hectiq-can-help.1")}</span>
                </li>
                <li className="inline-flex xl:block my-2 ml-2 text-lg text-gray-600 leading-[1.6]">
                  <span><p className="bg-[#3B487F] h-2 w-2 rounded-full mt-2 mr-5 inline-flex "/></span>
                  <span className="inline-flex">{t("hectiq-can-help.2")}</span>
                </li>
                <li className="inline-flex xl:block my-2 ml-2 text-lg text-gray-600 leading-[1.6]">
                  <span><p className="bg-[#3B487F] h-2 w-2 rounded-full mt-2 mr-5 inline-flex "/></span>
                  <span className="inline-flex">{t("hectiq-can-help.3")}</span>
                </li>
              </ul>
            </div>
          </div>
         
        </div>
}